import { configureStore } from '@reduxjs/toolkit';
import userReducer from './components/login/userSlice.js';
import cookieReducer from './components/cookie/cookieSlice';
import rcaReducer from './components/rca/rcaSlice';
import cartReducer from './components/cart/cartSlice';
import compareReducer from './components/compare/compareSlice';
var store = configureStore({
  reducer: {
    user: userReducer,
    cookie: cookieReducer,
    rca: rcaReducer,
    compare: compareReducer,
    cart: cartReducer
  },
  devTools: false ? true : false
});
export default store;