import React from "react";
var __jsx = React.createElement;
import { Provider } from 'react-redux';
import store from '../store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/styles/main.styles.css';

function MyApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  return __jsx(Provider, {
    store: store
  }, __jsx(Component, pageProps));
}

export default MyApp;